import React from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from '@mui/material';

export default function Homepage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: 4,
      }}
    >
      <Box textAlign="center" maxWidth={isMobile ? '90%' : '600px'} mb={6}>
        <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold" color="text.primary" gutterBottom>
          Collective Intelligence via Language Model Networks
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Explore multiple network types, achieve consensus in question answering, and chat with individual agents after responses.
        </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size={isMobile ? 'large' : 'medium'}
        href="/simulation"
        sx={{ paddingX: 4, paddingY: 1.5 }}
      >
        Get Started
      </Button>
    </Box>
  );
}
