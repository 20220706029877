import React, { useRef, useEffect } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import * as d3 from 'd3';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const COLORS = {
    A: '#4e79a7', // Steel Blue
    B: '#f28e2c', // Orange
    C: '#59a14f', // Green
    D: '#e15759', // Soft Red
    X: '#4d4d4d', // Teal
    default: '#4d4d4d' // Dark Gray (for default/unassigned)
};

const Graph = ({ graphData, responses, height, width, hoveredNode, onNodeHover, onNodeClick, loading, numRound, onReset }) => {
    const fgRef = useRef();

    useEffect(() => {
        if (fgRef.current) {
            fgRef.current.d3Force('charge', d3.forceManyBody().strength(-500));
            setTimeout(() => fgRef.current.zoomToFit(300), 3000);
        }
    }, [graphData]);

    const resetZoom = () => {
        if (fgRef.current) {
            fgRef.current.zoomToFit(300);
        }
    };

    const nodeColor = (node) => {
        try {
            return COLORS[responses[node.id].parsed_response] || COLORS['default'];
        } catch (error) {
            return COLORS['default'];
        }
    };

    const particleColor = (link) => {
        return nodeColor(link.source);
    };

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
            <ForceGraph2D
                ref={fgRef}
                graphData={graphData}
                nodeColor={nodeColor}
                width={width}
                height={height}
                minZoom={0.5}
                onNodeHover={onNodeHover}
                nodeCanvasObject={(node, ctx, globalScale) => {
                    const label = node.id;
                    const fontSize = 32 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    const nodeRadius = 5;
                    const color = nodeColor(node);

                    ctx.beginPath();
                    ctx.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI, false);
                    ctx.fillStyle = color;
                    ctx.fill();

                    if (node === hoveredNode) {
                        ctx.strokeStyle = 'black';
                        ctx.lineWidth = 1;
                        ctx.stroke();
                        ctx.fillStyle = 'black';
                        ctx.fillText(label, node.x, node.y - nodeRadius - 5);
                    }
                }}
                nodePointerAreaPaint={(node, color, ctx) => {
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
                    ctx.fillStyle = color;
                    ctx.fill();
                }}
                onNodeClick={node => {
                    if (fgRef.current) {
                        fgRef.current.centerAt(node.x, node.y, 1000);
                        fgRef.current.zoom(8, 500);
                    }
                    onNodeClick(node);
                }}
                linkWidth={1}
                linkDirectionalParticles={(link) => loading && numRound > 1 ? 3 : 0}
                linkDirectionalParticleSpeed={0.005}
                linkDirectionalParticleWidth={2}
                linkDirectionalParticleColor={particleColor}
            />

            {/* Top Controls Container */}
            <Box sx={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                right: '10px',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                {/* Round Indicator */}
                <Box sx={{
                    padding: '6px 12px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, color: '#666' }}>
                        Round
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#1976d2',
                            color: 'white',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}
                    >
                        {numRound}
                    </Box>
                </Box>

                {/* Controls */}
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    {/* Restart */}
                    <Tooltip title="Restart the simulation from the beginning">
                        <Button
                            onClick={onReset}
                            variant="outlined"
                            color="error"
                            size="small"
                            startIcon={<RestartAltIcon />}
                        >
                            Restart
                        </Button>
                    </Tooltip>

                    {/* Fit */}
                    <Tooltip title="Adjust the zoom to fit all nodes in the view">
                        <Button
                            onClick={resetZoom}
                            variant="outlined"
                            size="small"
                            startIcon={<ZoomOutMapIcon />}
                        >
                            Fit
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

export default Graph;
