import React from 'react';

// Match colors from Graph.js
const COLORS = {
    'A': '#4e79a7', // Steel Blue
    'B': '#f28e2c', // Orange
    'C': '#59a14f', // Green
    'D': '#e15759', // Soft Red
    'X': '#4d4d4d', // Dark Gray for NA/undefined
};

const ConsensusBox = ({ responses, propositions }) => {
    const calculateDistribution = () => {
      if (!responses || Object.keys(responses).length === 0) {
        return { distribution: {}, total: 0, simpsonIndex: 0 };
      }
  
      const distribution = {};
      let total = 0;
  
      Object.values(responses).forEach(response => {
        const answer = (response && response.parsed_response) ? response.parsed_response : 'NA';
        distribution[answer] = (distribution[answer] || 0) + 1;
        total++;
      });
  
      // Calculate Simpson Index
      const proportions = Object.values(distribution).map(count => count / total);
      const simpsonIndex = proportions.reduce((sum, p) => sum + p * p, 0);
  
      return { distribution, total, simpsonIndex };
    };
  
    const { distribution, total, simpsonIndex } = calculateDistribution();
    const answers = ['A', 'B', 'C', 'D', 'X'];
    const getPercentage = (count) => ((count || 0) / total) * 100;
  
    if (!total) {
      return <div className="p-4 text-gray-500">No responses available</div>;
    }
  
    return (
      <div className="p-4">
        <div className="space-y-3">
          {answers.map((answer, index) => {
            const percentage = getPercentage(distribution[answer]);
            const optionText = answer === 'X' ? 'No Answer' : propositions[index] || '';
            const truncatedOption = optionText && optionText.length > 20 ? `${optionText.slice(0, 20)}...` : optionText;
  
            return (
              <div key={answer}>
                <div className="flex justify-between text-sm mb-1">
                  <span>
                    {answer === 'X' ? 'No Answer' : `Option ${answer}`}
                    {answer !== 'X' && truncatedOption && (
                      <span className="text-gray-500 text-xs"> {truncatedOption}</span>
                    )}
                  </span>
                  <span>{Math.round(percentage)}%</span>
                </div>
                <div className="bg-gray-100 rounded-full h-1.5">
                  <div 
                    className="h-1.5 rounded-full transition-all duration-300" 
                    style={{ 
                      width: `${percentage}%`,
                      backgroundColor: COLORS[answer]
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
  
        <div className="mt-4 pt-4 border-t">
          <div className="text-sm">
            Consensus: {simpsonIndex.toFixed(3)}
            <span className="text-gray-500 text-xs ml-2">
              ({simpsonIndex > 0.7 ? 'Strong' : simpsonIndex > 0.5 ? 'Moderate' : 'Weak'})
            </span>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConsensusBox;
