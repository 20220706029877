import React, { useState, useRef, useEffect } from 'react';
import { Graph, AgentBox, QueryPanel, ChatBox, ConsensusBox, NavigationButtons } from "./../Components";
import { Button, Box, Grid, Typography, Tabs, Tab, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { Add, BarChart, Chat, Info } from '@mui/icons-material';
import templateList from "./../Templates/graphs_templates.json";
import { parseAgentResponse } from '../Utils';

export default function Simulation() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showInfoPanel, setShowInfoPanel] = useState(false);

    const initialGraphData = { nodes: [], links: [], responses: [] };
    const userId = localStorage.getItem('user_id');
    const [graphDataParam, setGraphDataParam] = useState(initialGraphData);
    const [question, setQuestion] = useState({"statement": "", "propositions": [ "", "", "", "" ]});
    const [nodeHover, setNodeHover] = useState(null);   
    const [nodeClick, setNodeClick] = useState(null);
    const [responses, setResponses] = useState({});
    const [numRound, setNumRound] = useState(0);
    const [containerSize, setContainerSize] = useState({ width: 400, height: 500 });
    const [experimentStarted, setExperimentStarted] = useState(false);
    const [simulationStarted, setSimulationStarted] = useState(false);
    const graphContainerRef = useRef(null);
    const [graphList, setGraphList] = useState([{nodes: [], links: [], responses: []}]);
    const [isOpenQuestion, setIsOpenQuestion] = useState(false);
    const [queryPanelOpen, setQueryPanelOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [chats, setChats] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetCounter, setResetCounter] = useState(0);

    const handleOpenQueryPanel = () => {
        setQueryPanelOpen(true);
    };

    const handleCloseQueryPanel = () => {
        setQueryPanelOpen(false);
    };

    const handleNodeClick = (node) => {
        setNodeClick(node);
        setNodeHover(null);
        if (isMobile) {
            setShowInfoPanel(true);
        }
    };

    const handleQuerySubmit = async (queryData) => {
        const newQuestion = {
            statement: queryData.question,
            propositions: queryData.options
        };
        setQuestion(newQuestion);
        setIsOpenQuestion(queryData.isOpenEnded);
        setChats({});
        setLoading(true);
        let newGraphData;
    
        if (queryData.customGraph) {
            newGraphData = queryData.customGraph;
        } else {
            const selectedTemplate = templateList.find(template => template.id === queryData.graphTemplate);
            if (selectedTemplate) {
                newGraphData = {
                    nodes: selectedTemplate.graph.nodes,
                    links: selectedTemplate.graph.links,
                    responses: selectedTemplate.graph.responses
                };
            } else {
                console.error("No matching template found");
                setLoading(false);
                return;
            }
        }
    
        const undirectedLinks = newGraphData.links.flatMap(link => [
            link,
            { source: link.target, target: link.source }
        ]);
    
        newGraphData = {
            ...newGraphData,
            links: undirectedLinks
        };
    
        setGraphDataParam(newGraphData);
    
        try {
            const response = await fetch("/api/simulation/begin", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "graph": newGraphData,
                    "question": newQuestion,
                    "is_open_question": isOpenQuestion,
                    "userId": userId
                }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            setNumRound(0);
            setExperimentStarted(true);
            setGraphList([newGraphData]);
            setSimulationStarted(false);
        } catch (error) {
            console.error("Error submitting question:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const updateContainerSize = () => {
            if (graphContainerRef.current) {
                setContainerSize({
                    width: graphContainerRef.current.clientWidth,
                    height: graphContainerRef.current.clientHeight
                });
            }
        };
        updateContainerSize();
        window.addEventListener('resize', updateContainerSize);
        return () => {
            window.removeEventListener('resize', updateContainerSize);
        };
    }, []);

    const resetGraph = (ev) => {
        if (ev) {
            ev.preventDefault();
        }
        fetch("/api/simulation", {
            method: "DELETE",
            body: JSON.stringify({ "userId": userId }),
        })
            .then(response => response.json())
            .then(_ => {
                setGraphDataParam(initialGraphData);
                setNumRound(0);
                setExperimentStarted(false);
                setResponses({});
                setChats({});
                setSimulationStarted(false);
                setGraphList([{ nodes: [], links: [], responses: [] }]);
                setResetCounter(prev => prev + 1); // Increment reset counter
                if (isMobile) {
                    setShowInfoPanel(false);
                }
            });
    };
    

    const nextRound = (ev) => {
        ev.preventDefault();
        const realNumRound = numRound + 1;
        setNumRound(realNumRound);
        if (realNumRound > graphList.length || (realNumRound === 1 && !simulationStarted)) {
            setLoading(true);
            fetch("/api/simulation/nextround", {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    "numRound" : realNumRound, 
                    "question" : question, 
                    "graphData" : graphList[graphList.length - 1],
                    "userId" : userId
                }),
            })
                .then(response => response.json())
                .then(data => {
                    const parsedResponses = parseAgentResponse(data.responses, isOpenQuestion);
                    const newGraph = {
                        nodes: data.nodes,
                        links: data.links,
                        responses: parsedResponses
                    };
                    if (realNumRound === 1 && !simulationStarted) {
                        setGraphList([newGraph]);
                    } else {
                        setGraphList(prevList => [...prevList, newGraph]);
                    }
                    setResponses(parsedResponses);
                    setSimulationStarted(true);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching next round data:", error);
                });
        } else {
            const responses = graphList[realNumRound - 1].responses;
            setResponses(responses);
        }
    };

    const previousRound = (ev) => {
        ev.preventDefault();
        var realNumRound = numRound - 1;
        setNumRound(realNumRound);
        if (numRound > 1) {
            try {
                const responses = graphList[realNumRound-1].responses;
                setResponses(responses);
            } catch (error) {
                console.error("Error loading previous graph:", error);
            }
        }
    };

    

    const InfoPanel = ({ showNavButtons = true }) => (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
                flexGrow: 1, 
                backgroundColor: 'white', 
                borderRadius: 2, 
                boxShadow: 3, 
                display: 'flex', 
                flexDirection: 'column',
                height: isMobile ? 'calc(100vh - 320px)' : '450px',
                overflow: 'hidden'
            }}>                
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* Always show tabs if simulation has started */}
                {experimentStarted && (
                    <>
                        {isMobile ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-around', 
                                borderBottom: 1, 
                                borderColor: 'divider',
                                backgroundColor: 'background.paper'
                            }}>
                                <IconButton 
                                    onClick={() => setTabValue(0)} 
                                    color={tabValue === 0 ? "primary" : "default"}
                                >
                                    <BarChart />
                                </IconButton>
                                <IconButton 
                                    onClick={() => setTabValue(1)} 
                                    color={tabValue === 1 ? "primary" : "default"}
                                >
                                    <Info />
                                </IconButton>
                                <IconButton 
                                    onClick={() => setTabValue(2)} 
                                    color={tabValue === 2 ? "primary" : "default"}
                                >
                                    <Chat />
                                </IconButton>
                            </Box>
                        ) : (
                            <Tabs 
                                value={tabValue} 
                                onChange={(e, newValue) => setTabValue(newValue)}
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Consensus" />
                                <Tab label="Info" />
                                <Tab label="Chat" />
                            </Tabs>
                        )}
                    </>
                )}
    
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    {!experimentStarted ? (
                        <Box sx={{ 
                            p: 3, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            textAlign: 'center',
                            gap: 2,
                            px: 8
                        }}>
                            <Typography variant="h6" color="primary">
                                Welcome to the Simulation
                            </Typography>
                            <Typography variant="body1">
                                1. Design a new query using the button above
                            </Typography>
                            <Typography variant="body1">
                                2. Press "Next" to start the simulation and collect responses
                            </Typography>
                            <Typography variant="body1">
                                3. Click on nodes to interact with individual agents
                            </Typography>
                        </Box>
                    ) : !simulationStarted ? (
                        <Box sx={{ 
                            p: 3, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            textAlign: 'center',
                            gap: 2
                        }}>
                            <Typography variant="h6" color="primary">
                                Ready to Begin
                            </Typography>
                            <Typography variant="body1">
                                Press "Next" to start collecting responses from the agents
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {tabValue === 0 ? (
                                <ConsensusBox responses={responses} propositions={question.propositions} />
                            ) : tabValue === 1 ? (
                                nodeClick ? (
                                    <AgentBox nodeClick={nodeClick} responses={responses} />
                                ) : (
                                    <Box sx={{ p: 3, textAlign: 'center' }}>
                                        <Typography>Select an agent to view their information</Typography>
                                    </Box>
                                )
                            ) : tabValue === 2 ? (
                                nodeClick ? (
                                    <ChatBox selectedNode={nodeClick} chats={chats} setChats={setChats} mostRecentRound={true} />
                                ) : (
                                    <Box sx={{ p: 3, textAlign: 'center' }}>
                                        <Typography>Select an agent to start chatting</Typography>
                                    </Box>
                                )
                            ) : (
                                <ConsensusBox responses={responses} />
                            )}
                        </>
                    )}
                </Box>
            </Box>
            </Box>
            {showNavButtons && (
                <NavigationButtons 
                    numRound={numRound}
                    experimentStarted={experimentStarted}
                    previousRound={previousRound}
                    nextRound={nextRound}
                    loading={loading}
                />
            )}
        </Box>
    );


    return (
        <Box sx={{ 
            mx: 'auto', 
            maxWidth: 1200, 
            p: { xs: 1, sm: 2, md: 3 },
            height: isMobile ? '100vh' : 'auto'
        }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenQueryPanel}
                sx={{ 
                    mb: 2,
                    width: isMobile ? '100%' : 'auto'
                }}
                startIcon={<Add />}
            >
                Design New Query
            </Button>

            <QueryPanel
                open={queryPanelOpen}
                onClose={handleCloseQueryPanel}
                onSubmit={handleQuerySubmit}
                templateList={templateList}
                reset={resetCounter}
            />

            {isMobile ? (
                <Box>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setShowInfoPanel(!showInfoPanel)}
                        sx={{ mb: 2 }}
                    >
                        {showInfoPanel ? 'Show Graph' : 'Show Info Panel'}
                    </Button>
                    <Box sx={{ 
                        display: showInfoPanel ? 'none' : 'block',
                        height: 'calc(100vh - 320px)'
                    }}>
                        <Box ref={graphContainerRef} sx={{ 
                            height: '100%', 
                            backgroundColor: 'white', 
                            borderRadius: 2, 
                            boxShadow: 3 
                        }}>
                            <Graph
                                graphData={graphDataParam}
                                responses={responses}
                                height={containerSize.height}
                                width={containerSize.width}
                                onNodeHover={(node) => setNodeHover(node)}
                                onNodeClick={handleNodeClick}
                                hoveredNode={nodeHover}
                                loading={loading}
                                numRound={numRound}
                                onReset={resetGraph}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: showInfoPanel ? 'block' : 'none' }}>
                        <InfoPanel showNavButtons={false} />
                    </Box>
                    <NavigationButtons 
                        numRound={numRound}
                        experimentStarted={experimentStarted}
                        previousRound={previousRound}
                        nextRound={nextRound}
                        loading={loading}
                    />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Box ref={graphContainerRef} sx={{ 
                            height: 600, 
                            backgroundColor: 'white', 
                            borderRadius: 2, 
                            boxShadow: 3 
                        }}>
                            <Graph
                                graphData={graphDataParam}
                                responses={responses}
                                height={containerSize.height}
                                width={containerSize.width}
                                onNodeHover={(node) => setNodeHover(node)}
                                onNodeClick={handleNodeClick}
                                hoveredNode={nodeHover}
                                loading={loading}
                                numRound={numRound}
                                onReset={resetGraph}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoPanel />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}