import React from 'react';
import { Box, Typography } from '@mui/material';

const AgentBox = ({ nodeClick, responses }) => {
  return (
    <Box sx={{ backgroundColor: 'white', borderRadius: 2, p: 2, minHeight: 200 }}>
      {nodeClick ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h6">Agent {nodeClick.id}</Typography>
          <Typography variant="body1">Current Answer:</Typography>
          {responses[nodeClick.id] ? (
            <>
              <Typography variant="body2">
                {responses[nodeClick.id].response}
              </Typography>
            </>
          ) : (
            <Typography variant="body2">No response for this round</Typography>
          )}
        </Box>
      ) : (
        <Typography variant="body1">Click on a node to see agent info</Typography>
      )}
    </Box>
  );
};

export default AgentBox;