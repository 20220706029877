import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Grid,
  Divider,
} from '@mui/material';
import {
  Help as HelpIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';

/**
 * Simple TabPanel component that displays children only when "value === index".
 * Ensures the content is allowed to overflow without clipping.
 */
function TabPanel({ children, value, index, ...other }) {
  return value === index ? (
    <Box
      role="tabpanel"
      id={`query-tabpanel-${index}`}
      aria-labelledby={`query-tab-${index}`}
      sx={{ overflow: 'visible', mt: 2 }}
      {...other}
    >
      {children}
    </Box>
  ) : null;
}

export default function QueryPanel({ open, onClose, onSubmit, templateList = [], reset }) {
  const [activeTab, setActiveTab] = useState(0);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '', '', '']);
  const [graphTemplate, setGraphTemplate] = useState('');
  const [customGraph, setCustomGraph] = useState(null);
  const [isValid, setIsValid] = useState(false);

  // Reset internal state when reset prop changes
  useEffect(() => {
    setActiveTab(0);
    setQuestion('');
    setOptions(['', '', '', '']);
    setGraphTemplate('');
    setCustomGraph(null);
  }, [reset]);

  // Validate the form whenever inputs change
  useEffect(() => {
    const isQuestionValid = question.trim().length > 0;
    const areOptionsValid = (activeTab === 1) || options.every((opt) => opt.trim().length > 0);
    const isGraphSelected = graphTemplate !== '' || customGraph !== null;
    setIsValid(isQuestionValid && areOptionsValid && isGraphSelected);
  }, [question, options, activeTab, graphTemplate, customGraph]);

  // Handle question text
  const handleQuestionChange = (event) => setQuestion(event.target.value);

  // Handle multiple-choice options
  const handleOptionChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
  };

  // Handle final submit
  const handleSubmit = () => {
    onSubmit({
      question,
      options: activeTab === 0 ? options : [],
      isOpenEnded: activeTab === 1,
      graphTemplate,
      customGraph,
    });
    onClose();
  };

  // Handle built-in graph template selection
  const handleGraphTemplateChange = (event) => {
    setGraphTemplate(event.target.value);
    setCustomGraph(null); // Clear custom graph when selecting a template
  };

  // Handle uploading custom graph JSON
  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('File selected:', file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          // Basic check for nodes & links
          if (!json.nodes || !json.links) {
            throw new Error('File must contain "nodes" and "links" arrays.');
          }
          setCustomGraph(json);
          setGraphTemplate(''); // Clear out template if a custom graph is uploaded
          console.log('Successfully parsed JSON file:', json);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    }
    event.target.value = ''; // Reset file input
  };

  // Clear the custom graph if user clicks the "X" icon
  const handleClearCustomGraph = () => {
    setCustomGraph(null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, bgcolor: 'primary.main', color: 'white' }}>
        Query Designer
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
          <Tab label="Multiple Choice" />
          <Tab label="Open-Ended" />
        </Tabs>

        <TextField
          fullWidth
          label="Enter your question"
          variant="outlined"
          value={question}
          onChange={handleQuestionChange}
          margin="normal"
          sx={{ mb: 2 }}
        />

        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={2}>
            {['A', 'B', 'C', 'D'].map((letter, idx) => (
              <Grid item xs={6} key={letter}>
                <TextField
                  fullWidth
                  label={`Option ${letter}`}
                  variant="outlined"
                  value={options[idx]}
                  onChange={(e) => handleOptionChange(idx, e)}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Typography variant="body1" color="textSecondary" align="center">
            Participants will provide their own answers to this open-ended question.
          </Typography>
        </TabPanel>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Graph Selection
          </Typography>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Graph templates</InputLabel>
              <Select
                value={graphTemplate}
                onChange={handleGraphTemplateChange}
                label="Graph templates"
                disabled={customGraph !== null}
              >
                {templateList.map(({ id, beautifullLabel }) => (
                  <MenuItem key={id} value={id}>
                    {beautifullLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tooltip title="Upload your own graph">
              <label htmlFor="custom-graph-input" style={{ cursor: 'pointer', marginLeft: '16px' }}>
                <IconButton color="primary" component="span">
                  <CloudUploadIcon />
                </IconButton>
              </label>
            </Tooltip>
            <input
              id="custom-graph-input"
              type="file"
              hidden
              accept=".json"
              onChange={handleFileUpload}
            />

            {customGraph && (
              <Tooltip title="Clear custom graph">
                <IconButton color="secondary" onClick={handleClearCustomGraph} sx={{ ml: 1 }}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {customGraph && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Custom graph uploaded: {customGraph.nodes.length} nodes,{' '}
              {customGraph.links.length} links
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button startIcon={<HelpIcon />}>Need Help?</Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" disabled={!isValid}>
          Create Query
        </Button>
      </DialogActions>
    </Dialog>
  );
}
