import React from 'react';
import { Box, Typography } from '@mui/material';

export default function Header() {
  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        padding: '16px',
      }}
    >
      <Typography
        variant="h6"
        component="a"
        href="/"
        sx={{
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}
      >
        Collective Intelligence 
      </Typography>
    </Box>
  );
}
