import React from 'react';
import { Box, Button, Tooltip, CircularProgress } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const NavigationButtons = ({ numRound, experimentStarted, previousRound, nextRound, loading }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      gap: 4, 
      mt: 3,
      mb: 2
    }}>
      <Tooltip title={numRound < 1 ? "No previous rounds" : "Go to previous round"}>
        <span>
          <Button
            variant="outlined"
            color="primary"
            disabled={numRound < 1}
            onClick={previousRound}
            sx={{
              width: '120px',
              height: '40px',
              borderRadius: '20px',
              textTransform: 'none',
              fontWeight: 'bold'
            }}
            startIcon={<ArrowBack />}
          >
            Previous
          </Button>
        </span>
      </Tooltip>

      <Tooltip title={!experimentStarted ? "Start the experiment first" : numRound >= 6 ? "Maximum rounds reached" : "Go to next round"}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={nextRound}
            disabled={!experimentStarted || numRound >= 6 || loading}
            sx={{
              width: '120px',
              height: '40px',
              borderRadius: '20px',
              textTransform: 'none',
              fontWeight: 'bold',
              position: 'relative'
            }}
            endIcon={!loading && <ArrowForward />}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    left: '50%',
                    marginLeft: '-12px'
                  }}
                />
                <span style={{ visibility: 'hidden' }}>Next</span>
              </>
            ) : (
              'Next'
            )}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default NavigationButtons;